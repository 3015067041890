@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.trapeze {
  border-bottom: 50px solid green;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  width: 50px;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* You can adjust the duration and timing function */
}

.fade-in.visible {
  opacity: 1;
}

/* src/index.css */

@font-face {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/JetBrainsMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/font/JetBrainsMonoNL-Bold.ttf") format("truetype");
}

.flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 1.5s;
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.rot {
  transform: rotateY(180deg);
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}

.flipped {
  transform: rotateY(180deg);
}
